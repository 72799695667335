<template>
  <!-- eslint-disable vue/no-v-html -->
  <component :is="tag" v-html="sanitizedContent" />
  <!--eslint-enable-->
</template>
<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import DOMPurify from 'isomorphic-dompurify';

export default defineComponent({
  name: 'HTMLContent',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    content: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    return {
      sanitizedContent: computed(() => DOMPurify.sanitize(props.content)),
    };
  },
});
</script>
